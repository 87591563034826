.pivot-plan-report{
    border-collapse: collapse;
}

.pivot-plan-report th, .pivot-plan-report td{
    min-width: 320px;
    max-width: 320px;
    border: 1px solid;
    font-weight: normal;
    border-collapse: collapse;
    word-wrap: break-word;
    word-break: break-word;
    border-collapse: collapse;
}

.pivot-plan-report th{
    padding: 7px;
    font-size: 12px;
    font-weight: bold;
}

.pivot-plan-report td{
    padding: 10px;
    font-size: 12px;
}