.header-rgRow{
    height: 150px!important;
}

.focused-rgRow .rgCell.disabled {
    font-weight: bolder!important;
    color: red !important;
}

.rgHeaderCell.focused-cell{
    font-weight: bolder!important;
    color: red !important;
}

.rgHeaderCell{
    border-right: 1px solid #f7f7f7;
}

.rgCell.disabled{
    background-color: white!important;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
}

.focused-rgRow{
    background-color: white!important;
}

.focused-rgRow .rgCell.disabled{
    background-color: #f7f7f7!important;
}