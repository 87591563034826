.pivot_table{
    font-size: 13px;
    width: 100%;
    border: 1px solid;
    border-collapse: collapse;
}

.pivot_table .main_th_cell, .pivot_table .main_td_cell{
    border: 1px solid;
    border-collapse: collapse;
    max-width: 90px;
    min-width: 90px;
    padding: 5px;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.pivot_table .main_th_cell:first-of-type, .pivot_table .main_td_cell:first-of-type{
    max-width: 20px;
    min-width: 20px;
}

.pivot_table .nested_th_cell, .pivot_table .nested_td_cell{
    /*max-width: 45px;*/
    /*min-width: 45px;*/
    text-align: center;
}

/*
.pivot_table .nested_th_cell:last-of-type, .pivot_table .nested_td_cell:last-of-type{
    border-left: 1px solid;
    border-collapse: collapse
}
 */

.pivot_table .main_td_cell{
    padding: 15px 0;
}

.input-area{
    font-size: 13px;
}

.input-area select, input{
    height: 25px;
    width: 100px;
}

.input-area__fullname{
    border: 1px solid;
    padding: 7px 7px;
    border-radius: 5px;
    position: relative;
}


.light{
    background: lightgoldenrodyellow
}

.dark{
    background: lightgoldenrodyellow;
    color: black;
}



.days-input-wrapper{
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
}

.days-input-wrapper .day-input-wrapper{
    width: 33.3%
}

.lesson-plan.delete-action{
    position: absolute;
    right: 252px;
    width: 221px;
}
.lesson-plan.cancel-action{
    position: absolute;
    right: 140px;
}
.lesson-plan.save-action{
    position: absolute;
    right: 10px;
}

/* Landscape phones and portrait tablets */
@media (max-width: 1100px) {
    .days-input-wrapper .day-input-wrapper{
        width: 50%
    }

    .input-area select, input{
        width: 140px;
    }
}


@media (min-width: 768px) and (max-width: 992px) {
    .days-input-wrapper .day-input-wrapper{
        width: 49.9%
    }

    .input-area select, input{
        width: 100px;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .days-input-wrapper .day-input-wrapper{
        width: 100%
    }

    .input-area select, input{
        width: 90px;
    }

    .lesson-plan.cancel-action{
        right: 10px;
        top: 78px;
    }

    .lesson-plan.delete-action{
        position: absolute;
        right: 10px;
        top: 45px;
    }
}

/* Landscape phones and smaller */
@media (max-width: 480px) {

}